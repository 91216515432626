import { mapGetters } from 'vuex'
import chargingpointCpoMixin from '@/mixins/chargingpoint/chargingpointCpoMixin'
import { provincieCodeByMunicipalityCode } from '@/services/municipalities'
import { sourceToSlug } from '@/services/sourceTranslation'
import { introducedBy, importSource } from '@/services/introductionSource'

export default {
  filters: {
    formatTokW: (x) => {
      return `${Math.floor(x / 1000)} kW`
    },
  },
  mixins: [chargingpointCpoMixin],
  data () {
    return {
      faseOptions: [{
        value: null,
        text: 'leeg',
      }, {
        value: 1,
        text: 'Fase 1',
      }, {
        value: 2,
        text: 'Fase 2',
    }],
    }
  },
  computed: {
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
    }),
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('config', ['getPhases']),
    isBraLiMunicipality() {
      return ([31, 30].includes(provincieCodeByMunicipalityCode({ code: this.getActiveMunicipality })))
    },
    code() {
      return this.chargingpoint.data.code
    },
    id() {
      return this.chargingpoint.data.properties.id
    },
    address() {
      return this.chargingpoint.data.address
    },
    addressSource() {
      return sourceToSlug({ source: this.chargingpoint.data.address?.source })
    },
    isAddressEditable() {
      // only NONE ecomovement chargingpoints are enabled for editing the address
      return this.chargingpoint && !this.chargingpoint.data.properties.ecomovement_id
    },
    hasAddress() {
      return !! this.chargingpoint.data.address
    },
    isAddressComplete() {
      return !!(
        this.chargingpoint.data.address?.street &&
        this.chargingpoint.data.address?.number &&
        this.chargingpoint.data.address?.postalcode &&
        this.chargingpoint.data.address?.city
      )
    },
    importSource() {
      return importSource(this.chargingpoint)
    },
    evse() {
      return this.chargingpoint.data.properties.evse
    },
    importedWithoutCoordinates() {
      return this.chargingpoint.data.meta?.hadCoordinates === false ?? false
    },
    coordinates() {
      // if the chargingpoint was merged, the coordinates are stored in merged_attributes. Use these coordinates if available
      const coordinates =  this.chargingpoint.data.merged_attributes?.coordinates ?? this.chargingpoint.data.coordinates

      if (!coordinates[0] || !coordinates[1]) {
        return false
      }

      return {
        'lng': coordinates[0],
        'lat': coordinates[1],
      }
    },
    prio() {
      return this.chargingpoint.data.prio
    },
    customPhase () {
      return this.getPhases.find(phase => phase.uuid === this.prio?.customPhase)?.label
    },
    trafficDecision () {
      return this.chargingpoint.data.trafficDecision
    },
    introducedBy() {
      return introducedBy(this.chargingpoint)
    },
    remark() {
      return this.chargingpoint.data.properties.remark ?? ''
    },
    stakeholders() {
      return this.chargingpoint.data.properties.stakeholders ?? []
    },
    status() {
      return this.chargingpoint.data.properties.status
    },
    user() {
      return this.chargingpoint.data.properties.user
    },
    types() {
      return this.chargingpoint.data.properties.type?.split('/')
    },
    power() {
      return this.chargingpoint.data.properties.power
    },
    nroutlets() {
      return this.chargingpoint.data.properties.nroutlets
    },
    beheerder() {
      return this.chargingpoint.data.properties.beheerder
    },
    validators() {
      return this.chargingpoint.data.properties.validators
    },
    predecessor() {
      return this.chargingpoint.data.properties.predecessor
    },
    hasValidators() {
      return this.validators?.length > 0
    },
    statusLabel() {
      return this.status ?? 'definitive'
    },
    closingDates(){
      return this.chargingpoint.data.participation?.closingDates
    },
  },
  created () {
    this.getPhases
      .filter(phase => phase.active)
      .map(phase => this.faseOptions.push({ value: phase.uuid, text: phase.label }))
  },
}
